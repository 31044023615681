/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@media screen and (max-width: 380px) {
  .infobox {
    ion-label {
      max-width: 140px;
    }
  }
}
ion-button {
  --border-radius: 100px;
  font-weight: 800;
}

.infobox_article {
  padding: 5px 10px 5px 10px;
  font-weight: 500;
}
.infobox_window {
  border-radius: 1000px 1000px 1000px 1000px !important;
  overflow: visible !important;
  top: 0;
  left: 0;
  .gm-style-iw-c {
    overflow: visible !important;
  }
  .gm-style-iw-d {
    overflow: visible !important;
  }
  button {
    background: #fff !important;
    border: 1px solid #40a8d1 !important;
    opacity: 1;
    top: 0px !important;
    right: 15px !important;
    width: 39px !important;
    border-radius: 100px;
    span {
      background-color: #40a8d1 !important;
    }
  }
}
.infobox_avis {
  padding: 3px 0px 3px 5px;
  font-weight: 500;
  width: 120px;
  height: 126px;
  padding-bottom: 15px;
  padding-right: 15px;
  overflow: hidden;
  border-radius: 1000px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1000px 1000px;
  }
}
.infobox {
  text-align: center !important;
  font-size: 15px !important;

  hr {
    margin: 3px;
  }
  ion-label {
    text-align: left;
    float: right;
    width: 165px;
    margin-left: 10px;
  }
  h3 {
    font-size: 17px;
    line-height: 19px;
    margin-top: -2px;
    margin-bottom: 4px;
  }
  .list_thumbnail {
    float: left;
    padding: 6px;
    border-radius: 100px;
    text-align: center;
    width: 42px;
    height: 42px;
    margin-top: 0px;
    background: gray;

    &.taille_1 {
      background: #f2cb21;
    }
    &.taille_2 {
      background: #e89800;
    }
    &.taille_3 {
      background: #e5424d;
    }
    .list_icon {
      width: 30px;
      object-fit: contain;
    }
    .list_taille {
      border: 1px solid red;
      width: calc(100% + 6px);
      float: left;
      font-size: 11px;
      font-weight: bold;
      border-radius: 100px;
      margin-top: 5px;
      margin-left: -3px;
      padding: 2px 2px 0px;
      line-height: 11px;

      &.taille_1 {
        border-color: #f2cb21;
      }
      &.taille_2 {
        border-color: #e89800;
      }
      &.taille_3 {
        border-color: #e5424d;
      }
    }
  }

  .list_ul {
    color: #393939;
    font-weight: bold;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
    .ville_abo {
      background: transparent;
      padding: 0px;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      top: 2px;
      position: relative;
      margin-right: 5px;
    }
    .picto_black {
      background: transparent;
      padding: 3px;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      top: 2px;
      position: relative;
      margin-right: 5px;
    }
    .picto_valide {
      background: #bbde5f;
      padding: 3px;
      height: 16px;
      width: 16px;
      border-radius: 100px;
      top: 2px;
      position: relative;
      margin-right: 5px;
    }
  }
}
.button-outline {
  --border-width: 2px;
  background: #fff !important;
  overflow: hidden;
  border-radius: 100px;
}
.alert-title {
  font-size: 25px !important;
}
ion-header .header-background {
  backdrop-filter: none !important;
}

.gm-ui-hover-effect {
  width: 40px !important;
  height: 40px !important;
  top: 0px !important;
  right: 0px !important;
}
.gm-ui-hover-effect img {
  width: 25px !important;
  height: 25px !important;
  margin: 8px !important;
}

ion-badge {
  text-transform: capitalize;
  margin-right: 5px;

  &.importance_urgent {
    background-color: var(--ion-color-danger);
  }
  &.importance_moyen {
    background-color: var(--ion-color-warning);
  }
  &.importance_faible {
    background-color: var(--ion-color-secondary);
  }
}
.gm-style .gm-style-iw-t::after {
  display: none !important;
}
ion-content {
  --color: #49463c;
}

.keyboard-is-open {
  ion-header,
  .picture-footer {
    display: none;
  }
}
.jumbotron {
  height: 134px;
  position: relative;
  overflow: hidden;
  .icon {
    position: absolute;
    left: 50%;
    margin-left: -40px;
    width: 91px;
    height: 91px;
    background: #fff;
    text-align: center;
    border-radius: 100px;
    margin-top: 22px;

    img {
      width: 53px;
      height: 53px;
      margin-top: 18px;
    }
  }
  img.background {
    filter: blur(23px);
    object-position: 0px -100px;
    object-fit: cover;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 134px;
    width: 100%;
    position: absolute;
  }
}
ion-content,
ion-header,
ion-footer,
ion-list,
.ion-page,
ion-item {
  --background: #fff !important;
  background: #fff !important;
}
ion-modal ion-title {
  font-size: 40px;
  text-align: center;
  line-height: 45px;
}
h2 {
  font-family: "Quicksand";
  font-size: 25px;
}
ion-header {
  ion-title {
    ion-icon {
      font-size: 45px;
    }
  }
}
.button-native {
  background: blue;
}

ion-button[fill="clear"] {
  font-weight: 400;
}
ion-button {
  --border-radius: 100px;
  font-weight: 800;
}
.modal-card.sc-ion-modal-ios-h .modal-wrapper.sc-ion-modal-ios {
  height: calc(100% - var(--ion-safe-area-top) - 0px);
  --overflow: hidden;
}
// .backdrop-no-scroll ion-router-outlet {
//   left: 10px !important;
//   right: 10px !important;
//   background: red !important;
// }

ion-modal.modal_final {
  font-family: "Quicksand";
  --background: #fff;
  --padding: 50% 0px;
  --text-align: center;
  --color: white;
  --font-size: 24px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
  h2 {
    font-size: 34px;
    font-weight: 800;
  }

  ion-content {
    --background: url(/assets/background.svg) no-repeat 42% 20% !important;
    // --background: var(--ion-color-primary, #3880ff) !important;
    --color: #fff;
    --padding-top: calc(50vh - 240px);

    .cube {
      background: #ffffff;
      padding: 50px 20px;
      border-radius: 100%;
      color: #40a8d1;
    }
  }
  .emoji {
    font-size: 90px;
    top: 30px;
    position: relative;
  }
}

.guest_footer {
  margin: 0px 0px 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  z-index: 99999;
  font-weight: 500;
  background: #fff;
  padding: 10px;
  text-align: center;
  box-shadow: -2px -2px 9px 0px #0000004f, -2px 2px 9px 0px #0000005e;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}
